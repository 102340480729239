import { useFrontContext } from '../providers/frontContext';
import {useState, useEffect} from 'react';
import React from 'react';

//const url = "https://172.14.11.99:5000/get?id="
const url = "https://frontapp.atomsupplies.com:5000/get?id="
//const url = "http://127.0.0.1:5000/get?id="



const CustomerInfo = order => {const context = useFrontContext();
    //all the useStates
    const [user,setUser] = useState("");
    const [customUser,setCustomUser] = useState("");

    const [address,setAddress] = useState("");
    const [customAddress,setCustomAddress] = useState("");

    const [orderID,setOrderID] = useState(ID);
    const [hide,setHide] = useState(false)
    const [customHide,setCustomHide] = useState(false)

    const [Show,toggleShow] = useState(false);
    const [errorCSS,seterrorCSS] = useState("email")

    const [customSearchFound,setCustomSearchFound] = useState()

    //Fetches API
    const getAPI = async (orderID) => {
        console.log("Function: getAPI")
        const response = await fetch(url+"5683689")
        .then((apibody) => apibody.json()
        .then((orderDetails) => {
            console.log(apibody.json())
            setUser(orderDetails)
            console.log()
        }))   
        return user

    }
    //body of email
    const body = (context.conversation && context.conversation.blurb) ? context.conversation.blurb : '[body]';
    //subject of email
    const subject = (context.conversation && context.conversation.subject) ? context.conversation.subject : '[Subject]';
    const user_email = (context.conversation && context.conversation.recipient && context.conversation.recipient.handle) ? context.conversation.recipient.handle : 'there';
    //finds order ID's in the subject and body
    var ID = find_orderID(subject + " " + body);
    //url for API
    //UseStates list

    if(ID != null){
        //if order ID has changed, It'll hide the results
        if(orderID[0] != ID[0]){
            console.log("OrderID changed!")
            setOrderID(ID) //Sets ID as the current one
            {setHide(false)} // Hides the results
        }
        //This is what happens when the search button is pressed 
        const onClickHandler = async () => {
            const user = await getAPI("5683689");
            console.log(user)
            setUser(user)
            setAddress(user.customerDeliveryAddress)
            setHide(true)
            if(String(user_email) != String(user.customerDispatchEmail)){
                console.log(user_email + user.customerDispatchEmail)
                seterrorCSS("emailError")
            }else{
                seterrorCSS("email")

            }

        }
        const getCustomAPI = async (customOrderID) => {
            var customNumber = customOrderID
            const response = await fetch(url+customOrderID);
            const APIbody = await response.json();
            console.log("URL: "+url)
        
            console.log("count" + Object.keys(body).length)
            if(Object.keys(APIbody).length > 0){

                return APIbody
            }else{
                console.log("No order found")
                return "error"
            }
        
        }

        const onClickCustom = async() => {
        toggleShow(!Show)
        const customUser = await getCustomAPI(getInputValue());
            if(customUser == "error"){
                setCustomSearchFound(false)
                console.log("ERROR")
                ID = null
            }else{
                setCustomSearchFound(true)
                ID = getInputValue();
                setCustomUser(customUser)
                setCustomAddress(customUser.address)
                setCustomHide(true)
                console.log(customUser)
            }
        }
        return(
            <div className="front-pluggin">
            <button onClick={onClickHandler}>Search Order {ID}</button><br />
            {(hide) && <div className="front-pluggin">
            <p className='front-pluggin-headding'>User's Order ID: <br></br>{ID}</p>
            <a className='front-pluggin-label' href="Google.com" target="_blank">Jump to Order</a>
            <b className='sub-headding'>Delivery address</b>
            <address className='front-pluggin'> 
            {address.suite}
            <br></br>
            {address.street}
            <br></br>
            {address.city}
            <br></br>
            {address.zipcode}
            <br></br>
            <address className={errorCSS}>{user_email} </address>

            </address>

            
            <table className='front-pluggin-table'>
                <tr className='sub-headding'>
                <th>Delivery</th>
                <th>Information</th>
                <th></th>
                </tr>
                <tr>
                <td>Tracking Number</td>
                <td>0099736462347234</td>
                <td></td>
                </tr>
                <tr>
                <td>Carrier Name:</td>
                <td>DPD</td>
                <td></td>
                </tr>
                <tr>
                <td>Dispatched on: </td>
                <td>25/05/2022</td>
                <td></td>
                </tr>
                <tr>
                <td>Arrival Date: </td>
                <td>26/05/2022</td>
                <td></td>
                </tr>
                <tr>
                <td>Progress: </td>
                <td>Delivered</td>
                <td></td>
                </tr>
            </table> 
            <b className='sub-headding'>Previous Orders</b>
            <table className="front-pluggin-table">                
            </table>
                </div>}
                <div>
            <b className='sub-headding'>Custom Order Search</b><br />
            <input type="text" id="IDField" name="IDField"></input>
            <button onClick={() => onClickCustom()}>{Show ? "Search" : "Search"}</button>
            {(searchbarFun()) && <div className="front-pluggin">

            <br></br>
            <p className='front-pluggin-headding'>Searched Order ID: <br></br> {getInputValue(customSearchFound)}</p>
            <a className='front-pluggin-label' href="Google.com" target="_blank">Jump to Order</a>
            <b className='sub-headding'>Delivery address</b>
            <address className='front-pluggin'> 
            {customAddress.suite}
            <br></br>
            {customAddress.street}
            <br></br>
            {customAddress.city}
            <br></br>
            {customAddress.zipcode}
            <br></br>
            {user_email}            
            </address>
            
            <table className='front-pluggin-table'>
                <tr className='sub-headding'>
                <th>Delivery</th>
                <th>Information</th>
                <th></th>
                </tr>
                <tr>
                <td>Tracking Number</td>
                <td>837612641264</td>
                <td></td>
                </tr>
                <tr>
                <td>Carrier Name:</td>
                <td>837612641264</td>
                <td></td>
                </tr>
                <tr>
                <td>Dispatched on: </td>
                <td>837612641264</td>
                <td></td>
                </tr>
                <tr>
                <td>Arrival Date: </td>
                <td>837612641264</td>
                <td></td>
                </tr>
                <tr>
                <td>Progress: </td>
                <td>837612641264</td>
                <td></td>
                </tr>
            </table> </div>}
            </div>
            </div>
        )
        
    
    }else{ 
        //Runs API for custom ID
        const getCustomAPI = async (customOrderID) => {
            var customNumber = customOrderID
            const response = await fetch(url+customOrderID);
            const customAPIbody = await response.json();
            console.log("URL: "+url)
        
            console.log("count" + Object.keys(customAPIbody).length)
            if(Object.keys(customAPIbody).length > 0){
                return customAPIbody
            }else{
                console.log("No order found")
                getInputValue("NoIDFound")
                return "error"
            }
        }
        //When the button gets clicked to search a custom order
        const onClickCustom = async() => {
        toggleShow(!Show)
        const customUser = await getCustomAPI(getInputValue());
            if(customUser == "error"){
                setCustomSearchFound(false)

                console.log("ERROR")
                ID = null
            }else{
                setCustomSearchFound(true)
                ID = getInputValue();
                setUser(customUser)
                setAddress(user.address)
                setHide(true)
            }
        }
        return(            
            <div>
            <b className='sub-headding'>Custom Order Search</b><br />
            <input type="text" id="IDField" name="IDField"></input>
            <button onClick={() => onClickCustom()}>{Show ? "Search" : "Search"}</button>
            {(searchbarFun()) && <div className="front-pluggin">
            <br></br>
            <p className='front-pluggin-headding'>Searched Order ID: <br></br> {getInputValue(customSearchFound)}</p>
            <a className='front-pluggin-label' href="Google.com" target="_blank">Jump to Order</a>
            <b className='sub-headding'>Delivery address</b>
            <address className='front-pluggin'> 
            {address.suite}
            <br></br>
            {address.street}
            <br></br>
            {address.city}
            <br></br>
            {address.zipcode}
            <br></br>
            {user_email}            
            </address>
            
            <table className='front-pluggin-table'>
                <tr className='sub-headding'>
                <th>Delivery</th>
                <th>Information</th>
                <th></th>
                </tr>
                <tr>
                <td>Tracking Number</td>
                <td>837612641264</td>
                <td></td>
                </tr>
                <tr>
                <td>Carrier Name:</td>
                <td>837612641264</td>
                <td></td>
                </tr>
                <tr>
                <td>Dispatched on: </td>
                <td>837612641264</td>
                <td></td>
                </tr>
                <tr>
                <td>Arrival Date: </td>
                <td>837612641264</td>
                <td></td>
                </tr>
                <tr>
                <td>Progress: </td>
                <td>837612641264</td>
                <td></td>
                </tr>
            </table> </div>}
            </div>
        );

    

}}
  
  export default CustomerInfo;

  function find_orderID(subject_and_body){

    var email_content = subject_and_body
    var email_IDs = email_content.match(/\b\d{7}\b/g);
    /* if there are more than 1 ID's then spit them out as an array, need to find a cleaner way to do this */
    if(JSON.stringify(email_IDs).length > 11){
        let array_IDs = [...new Set(email_IDs)]
        return JSON.stringify(array_IDs);
    }else{
        return email_IDs //email_IDs;

    }
}


function searchbarFun(field){
    if(document.getElementById("IDField")){

        if(document.getElementById("IDField").value.length > 0){
            return true
        }else{
            return false
        }
    }else{
        return false
    }
}
function getInputValue(userFound){
    if(userFound == false){
        console.log("NOIDFOUND")
        return "No ID Found"
    }
    if(document.getElementById("IDField")){
        return document.getElementById("IDField").value

    }else{
        return false
    }
}