import './App.css';
import { useFrontContext } from './providers/frontContext';
import FrontPlugin from './components/FrontPlugin';
import RedisTest from './components/RedisTest';
import CustomerInfo from './components/CustomerInfo copy';



function App(ID) {
  const context = useFrontContext();

  if (!context)
    return (
      <div className="App">
        {/* <p>Waiting to connect to the Front context.</p> */}
        <RedisTest />  

      </div>
    )

  switch(context.type) {
    case 'noConversation':
      return (
        <div className="App">
          <p>No conversation selected. Select a conversation to use this plugin.</p>
        </div>
      );
    case 'singleConversation':
			return (
				<div className="App">
				<FrontPlugin />  

				</div>)

    case 'multiConversations':
      return (
        <div className="App">
          <p>Multiple conversations selected. Select only one conversation to use this plugin.</p>
        </div>
      );
    default:
      console.error(`Unsupported context type: ${context.type}`);
      break;
  };
}

export default App;


