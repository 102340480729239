import { useFrontContext } from '../providers/frontContext';
import { useState } from 'react';
import React from 'react';

const url = "https://auto.atom.mom/api/getfrontdata?id=";

const FrontPlugin = (order) => {
  const context = useFrontContext();

  // State variables
  const [user, setUser] = useState(null);
  const [orderURL, setOrderURL] = useState(null);
  const [address, setAddress] = useState(null);
  const [orderID, setOrderID] = useState("");
  const [hide, setHide] = useState(false);
  const [Show, toggleShow] = useState(false);
  const [errorCSS, setErrorCSS] = useState("email");

  // Custom search states
  const [customUser, setCustomUser] = useState(null);
  const [customOrderURL, setCustomOrderURL] = useState(null);
  const [customAddress, setCustomAddress] = useState(null);
  const [customHide, setCustomHide] = useState(false);

  const [carrierURL, setCarrierURL] = useState();

  // Extracting information from context
  const body = context.conversation?.blurb || '[body]';
  const subject = context.conversation?.subject || '[Subject]';
  const user_email = context.conversation?.recipient?.handle || 'there';

  // Finding order ID in the subject and body
  const ID = find_orderID(`${subject} ${body}`);

  // Click handler for default search
  const onClickHandler = async () => {
    console.log("Search Button Pressed");
    const userDetails = await getAPI();
    setHide(true);
  };

  // Click handler for custom search
  const onClickCustom = async () => {
    console.log("Custom button pressed");
    await getCustomAPI();
    setCustomHide(true);
  };

  // Fetch order details using the default ID
  const getAPI = async () => {
    try {
      console.log("Getting API");
      const response = await fetch(url + ID[0]);
      const orderDetails = await response.json();
      setUser(orderDetails);
      setAddress(orderDetails.customerDeliveryAddress);
      setOrderURL(`https://www.masterofmalt.com/admin/ViewOrders.aspx?iOrder=${orderDetails.orderId}&currRowNum=1`);

      if (user_email.replace("googlemail", "gmail") !== orderDetails.customerDispatchEmail.replace("googlemail", "gmail")) {
        setErrorCSS("emailError");
      } else {
        setErrorCSS("email");
      }

      if (orderDetails.carrierName === "DPD") {
        setCarrierURL(`http://www.dpdlocal.co.uk/deliverysearch/#results&quickTrack=${orderDetails.trackingCodes}`);
      } else if (orderDetails.carrierName === "Evri") {
        setCarrierURL(`https://www.evri.com/track#/parcel/${orderDetails.trackingCodes}/details/`);
      }
    } catch (error) {
      console.error("Error fetching order details:", error);
    }
  };

  // Fetch order details using a custom ID
  const getCustomAPI = async () => {
    try {
      const searchValue = getInputValue();
      console.log(url + searchValue);
      const response = await fetch(url + searchValue);
      const customOrderDetails = await response.json();
      setCustomUser(customOrderDetails);
      setCustomAddress(customOrderDetails.customerDeliveryAddress);
      setCustomOrderURL(`https://www.masterofmalt.com/admin/ViewOrders.aspx?iOrder=${customOrderDetails.orderId}&currRowNum=1`);
    } catch (error) {
      console.error("Error fetching custom order details:", error);
    }
  };

  // Handle the case when an order ID is found
  if (ID !== null) {
    // Hide results if the order ID has changed
    if (orderID[0] !== ID[0]) {
      console.log("OrderID changed!");
      setOrderID(ID);
      setHide(false);
    }

    return (
      <div className="front-pluggin">
        <button onClick={onClickHandler}>Search Order {ID}</button>
        <br />
        {hide && (
          <div className="front-pluggin">
            <p className="front-pluggin-heading">Order Number: <br />{ID}</p>
            <a className="front-pluggin-label" href={orderURL} target="_blank" rel="noopener noreferrer">Jump to Order</a>
            <b className="sub-heading">Delivery address</b>
            <address className="front-pluggin">
              {address.firstName} {address.lastName}
              <br />
              {address.addressLine1}
              <br />
              {address.addressLine2}
              <br />
              {address.town}
              <br />
              {address.postcode}
              <br />
              <span className={errorCSS}>{user_email}</span>
            </address>

            <table className="front-pluggin-table">
              <thead>
                <tr className="sub-heading">
                  <th>Delivery</th>
                  <th>Information</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Tracking:</td>
                  <td>
                    <a href={carrierURL} target="_blank" rel="noopener noreferrer">{user.trackingCodes}</a>
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td>Carrier:</td>
                  <td>{user.carrierName}</td>
                  <td></td>
                </tr>
                <tr>
                  <td>Dispatched on:</td>
                  <td>{user.dateDispatched}</td>
                  <td></td>
                </tr>
                <tr>
                  <td>Arrival Date:</td>
                  <td>{user.dateDelivered}</td>
                  <td></td>
                </tr>
                <tr>
                  <td>Required Arrival Date:</td>
                  <td>{user.requiredArrivalDate}</td>
                  <td></td>
                </tr>
                <tr>
                  <td>Progress:</td>
                  <td>{user.orderStatus}</td>
                  <td></td>
                </tr>
              </tbody>
            </table>
            <b className="sub-heading">Previous Orders</b>
            <table className="front-pluggin-table">
              {/* Add previous orders here */}
            </table>
          </div>
        )}
        <div>
          <b className="sub-heading">Custom Order Search</b>
          <br />
          <input type="text" id="IDField" name="IDField"></input>
          <button onClick={onClickCustom}>{Show ? "Search" : "Search"}</button>
          {searchbarFun() && (
            <div className="front-pluggin">
              <br />
              <p className="front-pluggin-heading">Searched Number: <br /> {getInputValue()}</p>
              <a className="front-pluggin-label" href={customOrderURL} target="_blank" rel="noopener noreferrer">Jump to Order</a>
              <b className="sub-heading">Delivery address</b>
              <address className="front-pluggin">
                {customUser.firstName} {customUser.lastName}
                <br />
                {customUser.customerDeliveryAddress.addressLine1}
                <br />
                {customUser.customerDeliveryAddress.addressLine2}
                <br />
                {customUser.customerDeliveryAddress.town}
                <br />
                {customUser.customerDeliveryAddress.postcode}
                <br />
              </address>

              <table className="front-pluggin-table">
                <thead>
                  <tr className="sub-heading">
                    <th>Delivery</th>
                    <th>Information</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Tracking Number:</td>
                    <td>{customUser.trackingCodes}</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>Carrier Name:</td>
                    <td>{customUser.carrierName}</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>Dispatched on:</td>
                    <td>{customUser.dateDispatched}</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>Arrival Date:</td>
                    <td>{customUser.dateDelivered}</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>Progress:</td>
                    <td>{customUser.orderStatus}</td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    );
  } else {
    // Render when no ID is found
    return (
      <div>
        <b className="sub-heading">Custom Order Search</b>
        <br />
        <input type="text" id="IDField" name="IDField"></input>
        <button onClick={onClickCustom}>{Show ? "Search" : "Search"}</button>
        {searchbarFun() && (
          <div className="front-pluggin">
            <br />
            <p className="front-pluggin-heading">Searched Number: <br /> {getInputValue()}</p>
            <a className="front-pluggin-label" href={customOrderURL} target="_blank" rel="noopener noreferrer">Jump to Order</a>
            <b className="sub-heading">Delivery address</b>
            <address className="front-pluggin">
              {customUser.firstName} {customUser.lastName}
              <br />
              {customUser.customerDeliveryAddress.addressLine1}
              <br />
              {customUser.customerDeliveryAddress.addressLine2}
              <br />
              {customUser.customerDeliveryAddress.town}
              <br />
              {customUser.customerDeliveryAddress.postcode}
              <br />
            </address>

            <table className="front-pluggin-table">
              <thead>
                <tr className="sub-heading">
                  <th>Delivery</th>
                  <th>Information</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Tracking Number:</td>
                  <td>{customUser.trackingCodes}</td>
                  <td></td>
                </tr>
                <tr>
                  <td>Carrier Name:</td>
                  <td>{customUser.carrierName}</td>
                  <td></td>
                </tr>
                <tr>
                  <td>Dispatched on:</td>
                  <td>{customUser.dateDispatched}</td>
                  <td></td>
                </tr>
                <tr>
                  <td>Arrival Date:</td>
                  <td>{customUser.dateDelivered}</td>
                  <td></td>
                </tr>
                <tr>
                  <td>Progress:</td>
                  <td>{customUser.orderStatus}</td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
        )}
      </div>
    );
  }
};

export default FrontPlugin;

// Helper function to find order IDs
function find_orderID(subject_and_body) {
  const email_content = subject_and_body;
  const email_IDs = email_content.match(/\b\d{7}\b/g);
  if (email_IDs && email_IDs.length > 1) {
    return [...new Set(email_IDs)];
  } else {
    return email_IDs;
  }
}

// Helper function to check if the search bar has input
function searchbarFun() {
  const inputElement = document.getElementById("IDField");
  return inputElement && inputElement.value.length > 0;
}

// Helper function to get input value
function getInputValue() {
  const inputElement = document.getElementById("IDField");
  return inputElement ? inputElement.value : "";
}
