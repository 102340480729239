import {useState, useEffect} from 'react';
import React from 'react';


//const url = "http://172.14.11.99:5000/get?id="
//const url = "http://172.14.9.176:5000/get?id="
const url = "http://127.0.0.1:5000/get?id="




function RedisTest(){
    const onClickCustom = async() => {
        console.log("button pressed")
        const customUser = await getAPI();
    }
    const [user,setUser] = useState("");
    
    const getAPI = async () => {
        console.log("Getting API")
        var ID = document.getElementById("IDField").value
        const response = await fetch(url+ID)
        .then((body) => body.json()
        .then((orderDetails) => {
            setUser(orderDetails)
            console.log(user.customerDeliveryAddress)
        }))   
      
        return user
    
    }

return(
    <div>
        <b className='sub-headding'>Custom Order Search</b><br />
        <input type="text" id="IDField" name="IDField"></input>
        <button onClick={onClickCustom}>Click</button>
            
    </div>
);

}

export default RedisTest;
